/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import React, { useCallback } from "react";
import { eventCallbackTriggers } from "../templates/wppage";
import LayoutHome from "../layout/LayoutHome";

const OfflineDonationToSrcmUsa = () => {
  const pageContent =
    '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="15"' +
    'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
    'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
    'colorhighlight="#efeffe" titletext="General Donation SRCM, USA offline"/>';

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("srcm-usa-general-donation-offline", event),
    []
  );

  return (
    <LayoutHome seoTitle="Offline Donation to SRCM USA - Donations">
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box>
          <div style={{ lineHeight: "1.1", marginBottom: "8px" }}>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "2.5em",
                marginTop: "20px",
                fontWeight: "20px",
              }}
            >
              General Donation to SRCM, USA offline
            </h1>
          </div>
        </Box>
        <Box>
          <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
            All donations to Shri Ram Chandra Mission are voluntary. Shri Ram
            Chandra Mission is registered as a tax-exempt organization under
            section 501-C3 of the Internal Revenue Code. Donated funds are used
            for:
          </p>
          <ul>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Maintenance and support of existing ashrams and
                meditation centers across USA as well as the building and
                establishment of new SRCM facilities.
              </p>
            </li>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Hosting of seminars, training courses, and other
                activities at SRCM facilities.
              </p>
            </li>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Printing and distribution of publications and other
                multimedia content.
              </p>
            </li>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Support research, education and training courses to
                promote Sahaj Marg Heartfulness meditation and practice.
              </p>
            </li>
          </ul>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent}
          />
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default OfflineDonationToSrcmUsa;
